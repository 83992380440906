<template>
  <div>
    <ts-page-title
      :title="$t('stockIn.pageTitle')"
      :breadcrumb="[
        { text: $t('home'), href: '/' },
        {
          text: $t('stockIn.pageTitle'),
          href: '/admin/inventory/stock-ins',
        },
        {
          text: $t('edit'),
          active: true,
        },
      ]"
    />
    <ts-panel>
      <ts-panel-wrapper>
        <ts-loading-banner :loading="loading" class="tw-space-y-5">
          <div class="tw-space-y-5">
            <PurchaseOrderNumber
              v-model="model"
              :validate="errors"
              @loading="(v) => (loading = v)"
              class="tw-p-4"
            />
            <ProductDetail
              v-model="model"
              :validate="errors"
              class="tw-p-4 tw-max-h-screen"
            />
          </div>
          <div class="row">
            <div class="col-md-12 tw-space-x-4 tw-flex tw-justify-end">
              <ts-button @click.prevent="$router.push({ name: 'stock-in' })">{{
                $t("cancel")
              }}</ts-button>
              <ts-button
                color="primary"
                outline
                :waiting="waiting"
                @click.prevent="onUpdate"
                >{{ $t("update") }}</ts-button
              >
            </div>
          </div>
        </ts-loading-banner>
      </ts-panel-wrapper>
    </ts-panel>
  </div>
</template>

<script>
import moment from "moment";
import { Errors } from "form-backend-validation";
import ProductDetail from "./components/product-detail.vue";
import PurchaseOrderNumber from "./components/purchase-order-number.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "stockInCreate",
  components: {
    ProductDetail,
    PurchaseOrderNumber,
  },
  data() {
    return {
      errors: new Errors(),
      loading: false,
      waiting_new: false,
      waiting: false,
      show_pdf: false,
      src: null,
      model: {
        shop_id: null,
        invoice_no: null,
        stock_in_date: moment().format("DD-MM-YYYY"),
        fix_rate: null,
        remark: null,
        product_detail: [],
      },
    };
  },
  computed: {
    ...mapState("inventory/stockIn", ["edit_data"]),
  },
  methods: {
    ...mapActions("inventory/stockIn", ["getFormViewData", "find"]),
    fetchFormView({ params }) {
      this.loading = true;
      this.getFormViewData({ params: params })
        .catch((err) => {
          this.$notify({ type: "error", text: err.message });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onUpdate() {
      this.errors = new Errors();
      this.loading = true;
      this.$store
        .dispatch("inventory/stockIn/update", {
          id: this.edit_data.stock_in_id,
          data: this.model,
        })
        .then((response) => {
          this.$notify({ type: "success", text: response.message });
          this.$router.push({ name: "stock-in" });
        })
        .catch((error) => {
          this.errors = new Errors(error.errors);
          this.$notify({ type: "error", text: error.message });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async setEdit() {
      await this.find(this.$route.params.id);
      await this.fetchFormView({
        params: {
          shop_id: this.edit_data.shop_id,
        },
      });
      this.model.shop_id = this.edit_data.shop_id;
      this.model.invoice_no = this.edit_data.invoice_no;
      this.model.remark = this.edit_data.remarks;
      this.model.fix_rate = this.edit_data.fx_rate;
      this.model.stock_in_date = this.edit_data.stock_in_date;
      this.model.product_detail = this.edit_data.detail.map((el) => ({
        product_id: el.product_id,
        uom_id: el.uom_id,
        cost: el.cost,
        qty: el.quantity,
        expiry_date: el.expiry_date,
      }));
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setEdit();
    });
  },
};
</script>

<style lang="scss" scoped></style>
